import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import Boxes from "../components/crumbs/boxes"
import { useStaticQuery, graphql } from "gatsby"
import Title from "../components/crumbs/title"
import Layout6337 from "../components/layouts/layout6337"
import PageSeo from "../components/crumbs/pageSeo"
import Ads from "../components/ads"
import BoxAd from "../components/crumbs/boxAd"

const getCover = (article) => {
  if (article.cover === null || article.cover.childImageSharp === null || article.cover.childImageSharp.fluid === null) {
      return null
  } else {
      return article.cover.childImageSharp.fluid
  }
}

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      topNews: allStrapiArticle(limit: 32, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}}) {
        edges {
          node {
              title
              category {
                  name
              }
              cover {
                  childImageSharp {
                      fluid {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
              excerpt
          }
        }
      }

      belfold: allStrapiArticle(limit: 32, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Belföld"}}}) {
        edges {
          node {
              title
              category {
                  name
              }
              cover {
                  childImageSharp {
                      fluid {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
              excerpt
          }
        }
      }

      kulfold: allStrapiArticle(limit: 32, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Külföld"}}}) {
        edges {
          node {
              title
              category {
                  name
              }
              cover {
                  childImageSharp {
                      fluid {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
              excerpt
          }
        }
      }

      gazdasag: allStrapiArticle(limit: 32, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Gazdaság"}}}) {
        edges {
          node {
              title
              category {
                  name
              }
              cover {
                  childImageSharp {
                      fluid {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
              excerpt
          }
        }
      }
    }
  `)

  const articlesTopNews = data.topNews.edges.map(_ => _.node)
  const articlesBelfold = data.belfold.edges.map(_ => _.node)
  const articlesKulfold = data.kulfold.edges.map(_ => _.node)
  const articlesGazdasag = data.gazdasag.edges.map(_ => _.node)

  // WON'T CHANGE WHEN CONTENTS COMES FROM STRAPI
  // --------------------------------------------- MAIN SECTION
  const styles = {
    0:  1, 1:  1, 2:  2, 3:  2, 4:  2,
    5:  2, 6:  2, 7:  1, 8:  2, 9:  1,
    10: 2, 11: 2, 12: 17,
    13: 17, 14: 17, 15: 17, 16: 17, 17: 16,
    18: 16, 19: 16, 20: 12, 21: 10, 22: 10,
    23: 12, 24: 12, 25: 10, 26: 10, 27: 10,
    28: 2, 29: 2
  }
  
 // --------------------------------------------- TOPNEWS SECTION

  const articles = articlesTopNews.map((article, idx) => <Boxes box={styles[idx]} title={article.title} excerpt={article.excerpt} category={article.category.name} cover={getCover(article)} />)

  const mainTopLeft = [articles[0], articles[2], articles[4], articles[6], articles[7]]
  const mainTopRight = [articles[1], articles[3], articles[5], articles[8], articles[9]]
  const mainBottom = [articles[17], articles[18], articles[19]]
  const bottom = [articles[12], articles[13], articles[14], articles[15], articles[16]]
  const side = [articles[10], articles[11], <><script mode="block" data-zone="mp_adb_336280_zone1" src="https://head-clickfusion.com/client-v2.js"></script></>,
                <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/1213102.html" width="350" height="650" frameborder="0" scrolling="no"></iframe> } />, 
                articles[20], articles[21], articles[22], <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/netex323.html" width="350" height="300" frameborder="0" scrolling="no"></iframe> } />, articles[23],
                articles[24], <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/netex273.html" width="350" height="320" frameborder="0" scrolling="no"></iframe> }/>]

  // --------------------------------------------- BELFOLD SECTION
  const stylesBelfold = {
      0: 1, 1: 2, 2: 2, 3: 1,
      4: 2, 5: 2, 6: 2, 7: 2, 8: 12, 9: 12
  }

  const belfold = articlesBelfold.map((article, idx) => <Boxes box={stylesBelfold[idx]} title={article.title} excerpt={article.excerpt} category={article.category.name} cover={getCover(article)} />)
  const belfoldMainTopLeft = [belfold[0], belfold[1], belfold[2]]
  const belfoldMainTopRight = [belfold[3], belfold[4], belfold[5]]
  const belfoldMainBottom = <></>
  const belfoldBottom = <></>
  const belfoldSide = [belfold[6], belfold[7], belfold[8], belfold[9]]

  // --------------------------------------------- KULFOLD SECTION
  const stylesKulfold = {
    0: 1, 1: 2, 2: 2, 3: 1,
    4: 12, 5: 12, 6: 2, 7: 2, 8: 12, 9: 12
  }

  const kulfold = articlesKulfold.map((article, idx) => <Boxes box={stylesKulfold[idx]} title={article.title} excerpt={article.excerpt} category={article.category.name} cover={getCover(article)} />)
  const kulfoldMainTopLeft = [kulfold[0], kulfold[1], kulfold[4]]
  const kulfoldMainTopRight = [kulfold[2], kulfold[3], kulfold[5]]
  const kulfoldMainBottom = <></>
  const kulfoldBottom = <></>
  const kulfoldSide = [kulfold[6], kulfold[7], kulfold[8], kulfold[9]]

  // --------------------------------------------- GAZDASAG SECTION
  const stylesGazdasag = {
    0: 1, 1: 2, 2: 2, 3: 1,
    4: 12, 5: 12, 6: 2, 7: 2, 8: 12, 9: 12 
  }

  const gazdasag = articlesGazdasag.map((article, idx) => <Boxes box={stylesGazdasag[idx]} title={article.title} excerpt={article.excerpt} category={article.category.name} cover={getCover(article)} />)
  const gazdasagMainTopLeft = [gazdasag[0], gazdasag[1], gazdasag[4]]
  const gazdasagMainTopRight = [gazdasag[2], gazdasag[3], gazdasag[5]]
  const gazdasagMainBottom = <></>
  const gazdasagBottom = <></>
  const gazdasagSide = [gazdasag[6], gazdasag[7], gazdasag[8], gazdasag[9]]

  const ads2 = <Ads adNo={331} isBlock={false} />
  const ads3 = <Ads adNo={333} isBlock={false} />
  const mainBottomSecondary = <BoxAd isBlock={false} ad={ <div data-gz-block="17f029ff-a000-419d-88fd-926b9e8b1133"></div> } />
  const mainBottomThirdly = <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/1213092.html" width="700" height="600" frameborder="0" scrolling="no"></iframe> } />
  //<BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/1208915.html" width="700" height="600" frameborder="0" scrolling="no"></iframe> } />

  // --------------------------------------------- RETURNED VIEW
  return (
    <>
      {/*<Helmet><script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script></Helmet>*/}
      <PageSeo
        title="Minden Percben | Érdekes"
        description="Minden amiről érdemes tudnia hazánkban és a nagyvilágban. Mert minden perc érdekes!"
        keywords="hírek, érdekességek"
        canonical="https://www.mindenpercben.hu"
        canonicalMobile = "https://m.mindenpercben.hu/"        
      />
      <LayoutSite>
        <Layout6337
          mainTopLeft={mainTopLeft}
          mainTopRight={mainTopRight}
          mainBottom={mainBottom}
          mainBottomSecondary={mainBottomSecondary}
          mainBottomThirdly={mainBottomThirdly}
          side={side}
          bottomFirstRow={<><script mode="block" data-zone="mp_adb_336280_zone2" src="https://head-clickfusion.com/client-v2.js"></script></>}
          bottom={bottom}
        />

        <script mode="block" data-zone="mp_adb_336280_zone3" src="https://head-clickfusion.com/client-v2.js"></script>
        <BoxAd isBlock={true} ad={ <iframe src="https://mindenpercben.hu/r/1213104.html" width="1100" height="700" frameborder="0" scrolling="no"></iframe> } />

        <Title category="Belföld" />
        <Layout6337
          mainTopLeft={belfoldMainTopLeft}
          mainTopRight={belfoldMainTopRight}
          mainBottom={belfoldMainBottom}
          mainBottomSecondary={<></>}
          side={belfoldSide}
          bottomFirstRow={<></>}
          bottom={belfoldBottom}
        />

        <BoxAd isBlock={true} ad={ <iframe src="https://mindenpercben.hu/r/netex272.html" width="700" height="120" frameborder="0" scrolling="no"></iframe> } />

        <Title category="Külföld" />
        <Layout6337
          mainTopLeft={kulfoldMainTopLeft}
          mainTopRight={kulfoldMainTopRight}
          mainBottom={kulfoldMainBottom}
          mainBottomSecondary={<></>}
          side={kulfoldSide}
          bottomFirstRow={<></>}
          bottom={kulfoldBottom}
        />

        <BoxAd isBlock={true} ad={ <iframe src="https://mindenpercben.hu/r/1213172.html" width="1100" height="320" frameborder="0" scrolling="no"></iframe> } />

        <Title category="Gazdaság" />
        <Layout6337
          mainTopLeft={gazdasagMainTopLeft}
          mainTopRight={gazdasagMainTopRight}
          mainBottom={gazdasagMainBottom}
          mainBottomSecondary={<></>}
          side={gazdasagSide}
          bottomFirstRow={<></>}
          bottom={gazdasagBottom}
        />
      </LayoutSite>
    </>
  )
}
